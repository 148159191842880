import { FunctionComponent } from "react";
import { Route, Routes } from "react-router-dom";
import { Contact } from "./Contact";
import { Gallery } from "./Gallery";
import { Home } from "./Home";
import { NotFound } from "./NotFound";
import { ServicesOffered } from "./ServicesOffered";

export const App: FunctionComponent = () => {
  return (
    <>
      <Routes>
        <Route path="*" element={<NotFound />} />

        <Route path="/" element={<Home />} />

        <Route path="services" element={<ServicesOffered />} />
        <Route path="gallery" element={<Gallery />} />
        <Route path="contact" element={<Contact />} />
      </Routes>
    </>
  );
}