import { Button, Container, Heading, Highlight, Icon, Image, Stack, Text } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { BiPhoneCall } from "react-icons/bi";
import { HiSquares2X2 } from "react-icons/hi2";
import { MdOutlineDesignServices } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Page } from "./Page";

export const Home: FunctionComponent = () => {
   const navigate = useNavigate();

   return (
      <>
         <Page hideNavigation={true}>
            <Container
               width="100%"
               maxWidth="100%"
               display="flex"
               flexDir="row"
               justifyContent="center"
               flexWrap="wrap"
               marginTop="2em"
            >
               <Stack
                  direction="row"
                  spacing="2em"
               >
                  <Heading
                     height="100%"
                     alignContent="center"
                     justifyContent="center"
                     display="flex"
                     flexDir="column"
                  >
                     <Text>Hello, we are JefeLLC.</Text>
                  </Heading>
                  <Image
                     src="sean_working.JPG"
                     width={[125, 200, 400]}
                     height={[150, 250, 500]}
                     borderRadius="15px"
                  />
               </Stack>
            </Container>
            <Container
               width="100%"
               maxWidth="100%"
               display="flex"
               flexDir="row"
               justifyContent="center"
               flexWrap="wrap"
               marginTop="2em"
            >
               <Stack direction="column">
                  <Text
                     fontSize={["1em", "1.5em", "2em"]}
                     fontWeight="bold"
                     alignContent="center"
                     justifyContent="center"
                     display="flex"
                  >
                     <Highlight
                        query="insured professionals"
                        styles={{
                           marginLeft: "0.2em",
                           marginRight: "0.2em",
                           backgroundColor: "orange.100",
                           paddingLeft: "5px",
                           paddingRight: "5px",
                           maxHeight: ["1.5em", "1.5em", "2em"],
                        }}
                     >
                        We are insured professionals,
                     </Highlight>
                  </Text>
                  <Text
                     fontSize={["1em", "1.5em", "2em"]}
                     fontWeight="bold"
                     alignContent="center"
                     justifyContent="center"
                     textAlign="center"
                     display="flex"
                  >
                     striving to bring value and appeal to any property.
                  </Text>
               </Stack>
            </Container>
            <Container
               width="100%"
               maxWidth="100%"
               display="flex"
               flexDir="row"
               justifyContent="center"
               flexWrap="wrap"
               marginTop="2em"
            >
               <Stack
                  direction={["column", null, "row"]}
                  spacing={["1.5em", "1em", "1em"]}
               >
                  <Button
                     colorScheme="purple"
                     onClick={() => { navigate("/services"); }}
                     leftIcon={<Icon as={MdOutlineDesignServices} boxSize={[6, 7, 7]} />}
                     size="lg"
                  >
                     Services Offered
                  </Button>
                  <Button
                     colorScheme="purple"
                     onClick={() => { navigate("/gallery"); }}
                     leftIcon={<Icon as={HiSquares2X2} boxSize={[6, 7, 7]} />}
                     size="lg"
                  >
                     View Gallery
                  </Button>
                  <Button
                     colorScheme="purple"
                     onClick={() => { navigate("/contact"); }}
                     leftIcon={<Icon as={BiPhoneCall} boxSize={[6, 7, 7]} />}
                     size="lg"
                  >
                     Contact Me
                  </Button>
               </Stack>
            </Container>
         </Page >
      </>
   );
}