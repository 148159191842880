import { Center, Container, Image, SimpleGrid, Stack, Text } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { Page } from "./Page";
import { image_paths } from "./WorkImages";

export const Gallery: FunctionComponent = () => {
   return (
      <>
         <Page hideNavigation={false}>
            <Container
               width="100%"
               maxWidth="100%"
               display="flex"
               flexDir="row"
               justifyContent="center"
               flexWrap="wrap"
               marginTop="2em"
            >
               <Stack direction="column">
                  <Text
                     fontSize={["1em", "1.5em", "2em"]}
                     fontWeight="bold"
                     alignContent="center"
                     justifyContent="center"
                     textAlign="center"
                     display="flex"
                  >
                     Check out some snapshots of my latest projects!
                  </Text>
               </Stack>
            </Container>
            <Container
               paddingLeft="2em"
               paddingRight="2em"
               width="100%"
               maxWidth="100%"
               alignContent="center"
               marginBottom="3em"
            >
               <SimpleGrid columns={[2, 2, 3]} gap="1em" spacing="1em" width="100%" marginTop="2em">
                  {image_paths.map((imageName) => (
                     <Center>
                        <Image
                           src={imageName}
                           width={[150, 200, 600]}
                           height={[200, 250, 800]}
                           borderRadius="15px"
                        />
                     </Center>
                  ))}
               </SimpleGrid>
            </Container>
         </Page>
      </>
   );
}