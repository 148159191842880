import { Button, Container, Divider, Heading, Highlight, Icon, Stack, Text } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { BiPhoneCall } from "react-icons/bi";
import { BsTreeFill } from "react-icons/bs";
import { FaRoad } from "react-icons/fa";
import { GiGearHammer, GiGrass } from "react-icons/gi";
import { TbSnowflake } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { Page } from "./Page";

export const ServicesOffered: FunctionComponent = () => {
   const navigate = useNavigate();

   return (
      <>
         <Page hideNavigation={false}>
            <Container
               width="100%"
               maxWidth="100%"
               display="flex"
               flexDir="row"
               justifyContent="center"
               flexWrap="wrap"
               marginTop="2em"
               marginBottom="1em"
            >
               <Heading
                  fontSize={[23, 20, 30]}
               >
                  Here are the services I offer:
               </Heading>
            </Container>
            <Container
               width="100%"
               maxWidth="100%"
               display="flex"
               flexDir="column"
               justifyContent="center"
               alignContent="center"
               flexWrap="wrap"
            >
               <Stack
                  direction={["column", "row", "row"]}
                  boxShadow="md"
                  padding="1em"
                  borderRadius="5px"
               >
                  <Icon
                     as={BsTreeFill}
                     boxSize={[50, 20, 20]}
                     color="green.500"
                     alignSelf="center"
                  />
                  <Container>
                     <Text
                        textAlign="center"
                     >
                        <Highlight
                           query={[
                              "Tree Service:",
                              "latest equipment and techniques",
                              "we also offer removal services",
                              "Our team is fully insured",
                           ]}
                           styles={{
                              marginLeft: "0.05em",
                              marginRight: "0.05em",
                              textDecoration: "underline",
                              paddingLeft: "5px",
                              paddingRight: "5px",
                              maxHeight: ["1.5em", "1.5em", "2em"],
                           }}

                        >
                           Tree Service: We use the latest equipment and techniques to ensure that your tree is removed without causing damage to your property or the surrounding area.
                           In addition to tree removal, we also offer removal services to help eliminate any trace of the tree(s) from your property.
                        </Highlight>
                     </Text>
                  </Container>
               </Stack>
            </Container>
            <Divider marginTop="2em" marginBottom="2em" width="50%" alignSelf="center" />
            <Container
               width="100%"
               maxWidth="100%"
               display="flex"
               flexDir="column"
               justifyContent="center"
               alignContent="center"
               flexWrap="wrap"
            >
               <Stack
                  direction={["column", "row", "row"]}
                  boxShadow="md"
                  padding="1em"
                  borderRadius="5px"
               >
                  <Icon
                     as={GiGrass}
                     boxSize={[50, 20, 20]}
                     color="green.500"
                     alignSelf="center"
                  />
                  <Container>
                     <Text
                        textAlign="center"
                     >
                        <Highlight
                           query={[
                              "Lawn Care:",
                              "mowing, fertilizing, weed control",
                              "custom lawn care plan",
                              "we're here to help",
                           ]}
                           styles={{
                              marginLeft: "0.05em",
                              marginRight: "0.05em",
                              textDecoration: "underline",
                              paddingLeft: "5px",
                              paddingRight: "5px",
                              maxHeight: ["1.5em", "1.5em", "2em"],
                           }}

                        >
                           Lawn Care: Our team of professionals offers a range of lawn care services including mowing, fertilizing, weed control, and more.
                           We will work with you to create a custom lawn care plan that fits your specific needs and budget. Whether you need weekly maintenance or just occasional service, we're here to help.
                        </Highlight>
                     </Text>
                  </Container>
               </Stack>
            </Container>
            <Divider marginTop="2em" marginBottom="2em" width="50%" alignSelf="center" />
            <Container
               width="100%"
               maxWidth="100%"
               display="flex"
               flexDir="column"
               justifyContent="center"
               alignContent="center"
               flexWrap="wrap"
            >
               <Stack
                  direction={["column", "row", "row"]}
                  boxShadow="md"
                  padding="1em"
                  borderRadius="5px"
               >
                  <Icon
                     as={TbSnowflake}
                     boxSize={[50, 20, 20]}
                     color="blue.500"
                     alignSelf="center"
                  />
                  <Container>
                     <Text
                        textAlign="center"
                     >
                        <Highlight
                           query={[
                              "Snow Removal:",
                              "safe and accessible during the snowy season",
                              "snow plowing, shoveling, and de-icing",
                              "safe and accessible",
                           ]}
                           styles={{
                              marginLeft: "0.05em",
                              marginRight: "0.05em",
                              textDecoration: "underline",
                              paddingLeft: "5px",
                              paddingRight: "5px",
                              maxHeight: ["1.5em", "1.5em", "2em"],
                           }}

                        >
                           Snow Removal: Winter can be dangerous if you don't have a reliable snow removal plan in place. JefeLLC understands the importance of keeping your property safe and accessible during the snowy season.
                           We offer a variety of snow removal services, including snow plowing, shoveling, and de-icing to ensure that your property is safe and accessible.
                        </Highlight>
                     </Text>
                  </Container>
               </Stack>
            </Container>
            <Divider marginTop="2em" marginBottom="2em" width="50%" alignSelf="center" />
            <Container
               width="100%"
               maxWidth="100%"
               display="flex"
               flexDir="column"
               justifyContent="center"
               alignContent="center"
               flexWrap="wrap"
            >
               <Stack
                  direction={["column", "row", "row"]}
                  boxShadow="md"
                  padding="1em"
                  borderRadius="5px"
               >
                  <Icon
                     as={FaRoad}
                     boxSize={[50, 20, 20]}
                     color="gray.500"
                     alignSelf="center"
                  />
                  <Container>
                     <Text
                        textAlign="center"
                     >
                        <Highlight
                           query={[
                              "Hardscaping:",
                              "custom hardscape",
                              "beautiful hardscape",
                           ]}
                           styles={{
                              marginLeft: "0.05em",
                              marginRight: "0.05em",
                              textDecoration: "underline",
                              paddingLeft: "5px",
                              paddingRight: "5px",
                              maxHeight: ["1.5em", "1.5em", "2em"],
                           }}

                        >
                           Hardscaping: We work closely with you to design a custom hardscape that fits your specific needs and budget, and we offer customized pricing to ensure you get a fair and reasonable price for our services.
                           Contact us today to schedule a consultation and transform your outdoor space with a beautiful hardscape.
                        </Highlight>
                     </Text>
                  </Container>
               </Stack>
            </Container>
            <Divider marginTop="2em" marginBottom="2em" width="50%" alignSelf="center" />
            <Container
               width="100%"
               maxWidth="100%"
               display="flex"
               flexDir="column"
               justifyContent="center"
               alignContent="center"
               flexWrap="wrap"
            >
               <Stack
                  direction={["column", "row", "row"]}
                  boxShadow="md"
                  padding="1em"
                  borderRadius="5px"
               >
                  <Icon
                     as={GiGearHammer}
                     boxSize={[50, 20, 20]}
                     color="purple.500"
                     alignSelf="center"
                  />
                  <Container>
                     <Text
                        textAlign="center"
                     >
                        <Highlight
                           query={[
                              "Special Projects:",
                              "create their dream outdoor space",
                              "we am here to help",
                              "high-quality materials and equipment",
                              "completed to my clients' satisfaction"
                           ]}
                           styles={{
                              marginLeft: "0.05em",
                              marginRight: "0.05em",
                              textDecoration: "underline",
                              paddingLeft: "5px",
                              paddingRight: "5px",
                              maxHeight: ["1.5em", "1.5em", "2em"],
                           }}

                        >
                           Special Projects: We are always excited to take on special projects that help our clients create their dream outdoor space.
                           Whether you need a new garden, a custom hardscape, or any other special project, we are here to help.
                           We work closely with our clients to understand their vision and develop a customized plan that meets their specific needs and budget.
                           We use only high-quality materials and equipment to ensure that every project is completed to our clients' satisfaction.
                           We would love to hear about your ideas and work with you to bring them to life!
                        </Highlight>
                     </Text>
                  </Container>
               </Stack>
            </Container>
            <Divider marginTop="2em" marginBottom="2em" width="50%" alignSelf="center" />
            <Container
               width="100%"
               maxWidth="100%"
               display="flex"
               flexDir="column"
               justifyContent="center"
               alignContent="center"
               flexWrap="wrap"
               marginBottom="3em"
            >
               <Heading
                  fontSize={[23, 20, 30]}
               >
                  Ready to get started?
               </Heading>
               <Button
                  colorScheme="purple"
                  onClick={() => { navigate("/contact"); }}
                  leftIcon={
                     <Icon as={BiPhoneCall} boxSize={[4, 5, 7]} />
                  }
                  alignSelf="center"
                  marginTop="1em"
               >
                  Contact Me
               </Button>
            </Container>
         </Page>
      </>
   );
}