export const image_paths: string[] = [
   "working_images/1.JPG",
   "working_images/2.JPEG",
   "working_images/3.JPEG",
   "working_images/4.JPEG",
   "working_images/5.JPEG",
   "working_images/6.JPEG",
   "working_images/7.JPEG",
   "working_images/8.JPEG",
   "working_images/10.JPEG",
   "working_images/11.JPEG",
   "working_images/12.JPEG",
   "working_images/13.JPEG",
   "working_images/14.JPEG",
]