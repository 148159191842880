import { Button, Card, CardBody, CardFooter, CardHeader, Container, Divider, Icon, Stack, Text } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { BiPhoneCall } from "react-icons/bi";
import { MdOutlineEmail } from "react-icons/md";
import { Page } from "./Page";

export const Contact: FunctionComponent = () => {
   return (
      <>
         <Page hideNavigation={false}>
            <Container
               width="100%"
               maxWidth="100%"
               display="flex"
               flexDir="row"
               justifyContent="center"
               flexWrap="wrap"
               marginTop="2em"
            >
               <Stack direction="column">
                  <Text
                     fontSize={["1em", "1.5em", "2em"]}
                     fontWeight="bold"
                     alignContent="center"
                     justifyContent="center"
                     textAlign="center"
                     display="flex"
                  >
                     We can't wait to help you transform your property!
                  </Text>
               </Stack>
            </Container>
            <Container
               width="100%"
               maxWidth="100%"
               display="flex"
               flexDir="row"
               justifyContent="center"
               flexWrap="wrap"
               marginTop="2em"
            >
               <Card margin="1em" minWidth="13em">
                  <CardHeader
                     padding="0.5em"
                  >
                     <Container
                        padding="0"
                        margin="0"
                        display="flex"
                        alignContent="center"
                        flexWrap="wrap"
                        justifyContent="center"
                     >
                        <Icon
                           as={MdOutlineEmail}
                           color="purple.500"
                           boxSize={[6, 6, 7]}
                           marginRight="0.5em"
                        />
                        <Text
                           fontSize={[15, 15, 18]}
                           fontWeight="bold"
                        >
                           Email Address
                        </Text>
                     </Container>
                  </CardHeader>
                  <CardBody display="flex" justifyContent="center">
                     <Text
                        fontSize={[15, 15, 18]}
                     >
                        jefellc40@gmail.com
                     </Text>
                  </CardBody>
                  <Divider color="gray.100" />
                  <CardFooter
                     display="flex"
                     justifyContent="center"
                  >
                     <form action="mailto:jefellc40@gmail.com?subject=Requesting a Quote">
                        <Button
                           colorScheme="purple"
                           type="submit"
                        >
                           Send Email
                        </Button>
                     </form>
                  </CardFooter>
               </Card>
               <Card margin="1em" minWidth="13em">
                  <CardHeader
                     padding="0.5em"
                  >
                     <Container
                        padding="0"
                        margin="0"
                        display="flex"
                        alignContent="center"
                        flexWrap="wrap"
                        justifyContent="center"
                     >
                        <Icon
                           as={BiPhoneCall}
                           color="purple.500"
                           boxSize={[6, 6, 7]}
                           marginRight="0.5em"
                        />
                        <Text
                           fontSize={[15, 15, 18]}
                           fontWeight="bold"
                        >
                           Phone Number
                        </Text>
                     </Container>
                  </CardHeader>
                  <CardBody display="flex" justifyContent="center">
                     <Text
                        fontSize={[15, 15, 18]}
                     >
                        (816) 883 9821
                     </Text>
                  </CardBody>
                  <Divider color="gray.100" />
                  <CardFooter
                     display="flex"
                     justifyContent="center"
                  >
                     <form action="tel:8168839821">
                        <Button
                           colorScheme="purple"
                           type="submit"
                        >
                           Call
                        </Button>
                     </form>
                  </CardFooter>
               </Card>
            </Container>
         </Page>
      </>
   );
}