import { Box, Container, Icon, IconButton, Image, Link, Text } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { BiPhoneCall } from "react-icons/bi";
import { HiOutlineHome, HiSquares2X2 } from "react-icons/hi2";
import { MdOutlineDesignServices } from "react-icons/md";
import { Link as RouterLink, useNavigate } from "react-router-dom";

interface PageProps {
    children?: React.ReactNode;
    hideNavigation: boolean;
}

export const Page: FunctionComponent<PageProps> = (props: PageProps) => {
    const navigate = useNavigate();

    return (
        <Container
            maxW="100vw"
            maxH="100vh"
            w="100vw"
            h="100vh"
            display="flex"
            flexDir="column"
            padding="0"
            margin="0"
            overflow="auto"
        >
            <Container
                padding="1em"
                width="100vw"
                maxWidth="100vw"
                height={["9vh", "7vh", "7vh"]}
                minHeight={["9vh", "7vh", "7vh"]}
                maxHeight={["9vh", "7vh", "7vh"]}
                backgroundColor="#392f5a"
                display="flex"
                flexDir="row"
                alignContent="center"
                flexWrap="wrap"
                justifyContent="center"
            >
                {props.hideNavigation && (
                    <Link
                        as={RouterLink}
                        to="/"
                    >
                        <Image
                            src="jefellclogo.png"
                            alt="Jefe LLC Logo"
                            width="100px"
                            height="45px"
                        />
                    </Link>
                )}
                {!props.hideNavigation && (
                    <Container
                        margin="0"
                        padding="0"
                        display="flex"
                        flexDir="row"
                        alignContent="center"
                        justifyContent="space-between"
                        height="100%"
                        maxW="none"
                    >
                        <Box
                            margin="0"
                            padding="0"
                            maxW="none"
                            maxH="none"
                            display="flex"
                            alignContent="center"
                            flexWrap="wrap"
                        >
                            <IconButton
                                aria-label="Home button"
                                onClick={() => { navigate("/"); }}
                                marginLeft={["0.5em", null, "1em"]}
                                marginRight={["1.5em", null, "1em"]}
                                fontSize="20"
                                backgroundColor="transparent"
                                color="orange.300"
                                variant="unstyled"
                                icon={
                                    <Icon
                                        as={HiOutlineHome}
                                        boxSize={8}
                                    />
                                }
                            />
                        </Box>
                        <Box
                            margin="0"
                            padding="0"
                            maxW="none"
                            maxH="none"
                            display="flex"
                            alignContent="center"
                            flexWrap="wrap"
                        >
                            <IconButton
                                aria-label="Services button"
                                onClick={() => { navigate("/services"); }}
                                marginLeft={["0.5em", null, "1em"]}
                                marginRight={["0.5em", null, "1em"]}
                                fontSize="20"
                                backgroundColor="transparent"
                                color="white"
                                variant="unstyled"
                                icon={
                                    <Icon
                                        as={MdOutlineDesignServices}
                                        boxSize={8}
                                    />
                                }
                            />
                            <IconButton
                                aria-label="Gallery button"
                                onClick={() => { navigate("/gallery"); }}
                                marginLeft={["0.5em", null, "1em"]}
                                marginRight={["0.5em", null, "1em"]}
                                fontSize="20"
                                color="white"
                                variant="unstyled"
                                icon={
                                    <Icon
                                        as={HiSquares2X2}
                                        boxSize={8}
                                    />
                                }
                            />
                            <IconButton
                                aria-label="Contact button"
                                onClick={() => { navigate("/contact"); }}
                                marginLeft={["0.5em", null, "1em"]}
                                marginRight={["0.5em", null, "1em"]}
                                fontSize="20"
                                backgroundColor="transparent"
                                color="white"
                                variant="unstyled"
                                icon={
                                    <Icon
                                        as={BiPhoneCall}
                                        boxSize={8}
                                    />
                                }
                            />
                        </Box>
                    </Container>
                )}
            </Container>
            <Container
                padding="0"
                width="100vw"
                maxWidth="100vw"
                margin="0"
                display="flex"
                flexDir="column"
                overflow="auto"
                maxHeight="none"
            >
                {props.children}
            </Container>
            <Container
                padding="1em"
                width="100vw"
                maxWidth="100vw"
                height="5vh"
                backgroundColor="#392f5a"
                display="flex"
                flexDir="row"
                alignContent="center"
                flexWrap="wrap"
                justifyContent="space-between"
                marginTop="auto"
            >
                <Text
                    fontSize={[12, 15, 20]}
                    justifySelf="center"
                    color="white"
                >
                    © JefeLLC. All rights reserved.
                </Text>
                <Text
                    fontSize={[12, 15, 20]}
                    justifySelf="end"
                    color="white"
                >
                    (816) 883 9821
                </Text>
            </Container>
        </Container>
    );
}