import { FunctionComponent } from "react";

export const NotFound: FunctionComponent = () => {
    return (
        <>
            Jefe LLC

            Page not found
        </>
    );
}